<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title">
                    <h3 class="card-label">
                        {{ $t('purchases_invoices.purchases_invoices')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/purchases/purchases-invoices" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link>
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                    <b-tabs content-class="mt-3">
                        <b-tab :title="$t('activity_log.basic_information')" active>
                            <br>
                            <div>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <th>{{$t('purchases_invoices.title')}}</th>
                                            <td>{{data.title}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.supplier')}}</th>
                                            <td>{{data.supplier_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.purchase_code')}}</th>
                                            <td>{{data.purchase_code}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.payment_terms')}}</th>
                                            <td>{{data.payment_terms}} {{$t('purchases_invoices.day_s')}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.currency')}}</th>
                                            <td>{{data.currency_name}}</td>
                                        </tr>

                                        <tr>
                                            <th>{{$t('purchases_invoices.shipping_fees')}}</th>
                                            <td>{{data.shipping_fees}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.inventory')}}</th>
                                            <td>{{data.inventory_name}}</td>
                                        </tr>
                                        <tr>
                                            <th>{{$t('purchases_invoices.notes')}}</th>
                                            <td>{{data.notes}}</td>
                                        </tr>

                                        <tr v-if="data.attachment_url">
                                            <th>{{$t('expenses.attachment')}}</th>
                                            <td><a :href="data.attachment_url" target="_blank">{{$t('expenses.attachment')}}</a></td>
                                        </tr>
                                        <tr v-if="data.created_at">
                                            <th>{{$t('created_at')}}</th>
                                            <td>{{data.created_at}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-if="data.items_list && data.items_list.length" class="form-group row mt-15">
                                    <div class="col-md-12">
                                        <hr>
                                    </div>
                                    <div class="col-md-12 d-flex justify-content-between">
                                        <h6 class="mt-2">{{$t('purchases_invoices.items')}}</h6>
                                    </div>
                                    <div class="col-md-12">
                                        <table class="table table-row-bordered">
                                            <thead>
                                            <tr>
                                                <th>
                                                    <div class="d-flex justify-content-between">
                                                        <span>{{$t('purchases_invoices.item')}}</span>
                                                    </div>
                                                </th>
                                                <th>{{$t('purchases_invoices.description')}}</th>
                                                <th width="10%">{{$t('purchases_invoices.unit_price')}}</th>
                                                <th width="10%">{{$t('purchases_invoices.qty')}}</th>
                                                <th>{{$t('purchases_invoices.tax')}}</th>
                                                <th width="10%">{{$t('purchases_invoices.discount_type')}}</th>
                                                <th width="10%">{{$t('purchases_invoices.discount_value')}}</th>
                                                <th width="10%">{{$t('purchases_invoices.subtotal')}}</th>
                                            </tr>

                                            </thead>
                                            <tbody>
                                            <tr v-for="(row, index) in data.items_list" :key="index">
                                                <td>{{row.item ? row.item.name : ''}}</td>
                                                <td>{{row.description}}</td>
                                                <td>{{row.unit_price}}</td>
                                                <td>{{row.qty}}</td>
                                                <td>{{row.tax ? row.tax.name : ''}}</td>
                                                <td>{{row.discount_type}}</td>
                                                <td>{{row.discount_value}}</td>
                                                <td>{{row.subtotal}}</td>

                                            </tr>
                                            </tbody>
<!--                                            <tfoot>-->
<!--                                            <tr>-->
<!--                                                <td colspan="6"></td>-->
<!--                                                <th>{{$t('purchases_invoices.items_total')}}</th>-->
<!--                                                <th><span>{{data.items_total}}</span></th>-->
<!--                                                <th></th>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td class="border-0" colspan="6"></td>-->
<!--                                                <th>{{$t('purchases_invoices.total_tax')}}(%)</th>-->
<!--                                                <th><span>{{data.total_tax}}</span></th>-->
<!--                                                <th class="border-0"></th>-->
<!--                                            </tr>-->
<!--                                            <tr>-->
<!--                                                <td class="border-0" colspan="6"></td>-->
<!--                                                <th>{{$t('purchases_invoices.total')}}</th>-->
<!--                                                <th><span>{{data.total}}</span></th>-->
<!--                                                <th class="border-0"></th>-->
<!--                                            </tr>-->
<!--                                            </tfoot>-->
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab :title="$t('activity_log.activity_log')">
                            <br>
                            <activity-log :inner-key="key" :id="id"></activity-log>
                        </b-tab>
                    </b-tabs>
                </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import ActivityLog from "@/view/content/widgets/details/ActivityLog";

    export default {
        name: "view-purchases-invoices",
        components:{'activity-log': ActivityLog},
        data() {
            return {
                mainRoute: 'purchases/purchases-invoices',
                id: this.$route.params.id? this.$route.params.id : null,
                key: 'PurchaseInvoice',
                data: {},
            };
        },

        methods: {
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
                    this.data = response.data.data;
                });
            },
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.purchases_invoices")}]);
            if (this.id) {
                this.getData();
            }

        },
    };
</script>